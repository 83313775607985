/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { CountryDropdown } from '../CountryDropdown';
import filter from 'lodash/filter';
import { Formik, ErrorMessage } from 'formik';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';

import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import { ClipLoader } from 'react-spinners';
import { UsStateDropdown } from '../UsStateDropdown';
import { notify } from '../CommonNotification/CommonNotification';
import usStateList from '../../data/UsState.json';
import countryList from '../../data/Country.json';
import canadaStateList from '../../data/CanadaState.json';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import * as antDesign from 'antd';
import Select from 'react-select';
import { history } from '../../history';
// import { paymentActions } from '../../actions';

const cookies = new Cookies();
const { Divider } = antDesign;
// const { REACT_APP_AWSServerURL } = process.env;

export const PaymentForm = ({
  t,
  match,
  onSubmitHandler,
  customerCardDetails,
  customerAddress,
  getPayableAmount,
  getTax,
  tax,
  currency,
  amount,
  countryChange,
  isCouponApplied,
  getPlanButton,
  coupon,
  onCouponChangeHandler,
  applycoupon,
  removeCouponHandler,
  kitConfig,
  disableCouponApply,
  isZipValid,
  taxLoading,
  percent_off,
  amount_off,
  licenceKey,
  restrictKey,
  customerDetails,
  state,
  regionValue2,
  regionChange,
  regionList,
  retriveModalDetails,
  showHideLoader,
  submitDisabled,
  isIndianCustomer,
  addCard,
  interval,
  scnumber,
  unit,
}) => {
  const [hide1, setHide1] = useState(false);
  const [hide2, setHide2] = useState(true);
  const [hide3, setHide3] = useState(true);
  const [useNewCard, setNewCard] = useState(false);
  const [brandType, setBrandType] = useState('');
  const [paymentMethodSelect, setPaymentMethodSelect] = useState('card');
  const [userFormData, setUserFormData] = useState({});
  const stripe = useStripe();
  const elements = useElements();
  const regex = /^[ \w]+$/;
  const regex2 = /^[ A-Za-z0-9,_@./#&-]*$/;
  const regexZipcode = /^\w{1,}(?:[-\s]\w{1,})?$/;
  //const billingAddress = (customerAddress.country !== '' && customerAddress.city !== '' && customerAddress.address1 !== '' && customerAddress.state !== '' && customerAddress.zipcode !== '')
  const billingAddress =
    customerDetails &&
    customerDetails.address &&
    customerDetails.address.country;
  const last4 = customerCardDetails ? customerCardDetails.last4 : false;
  useEffect(() => {
    if (licenceKey() && isCouponApplied() && (billingAddress || last4)) {
      setHide1(true);
      setHide2(true);
      setHide3(false);
    } else if (!licenceKey() && isCouponApplied()) {
      setHide1(true);
      setHide2(false);
      setHide3(true);
    } else if (licenceKey() && isCouponApplied() && !billingAddress) {
      setHide1(true);
      setHide2(false);
      setHide3(true);
    }
  }, [
    billingAddress,
    coupon,
    isCouponApplied,
    last4,
    percent_off,
    amount_off,
    licenceKey,
    isIndianCustomer,
  ]);

  const createStripeToken = async (values) => {
    let additionalData = {
      name: values.fullname,
      address_line1: values.address1,
      address_line2: values.address2,
      address_country: values.country.label,
      address_city: values.city,
      address_state: values.state,
      address_zip: values.zipcode,
    };
    if (!stripe || !elements) {
      return false;
    } else {
      try {
        showHideLoader();
        const token = await stripe.createToken(
          elements.getElement(CardNumberElement),
          additionalData,
        );
        showHideLoader();
        token.error
          ? notify('error', token.error.message)
          : onSubmitHandler(token.token, values);
      } catch (err) {
        notify('error', err.message);
      }
    }
  };

  const next = (value) => {
    switch (value) {
      case '1':
        if (coupon) {
          if (licenceKey() && isCouponApplied() && (billingAddress || last4)) {
            setHide3(false);
            setHide2(true);
            setHide1(true);
          } else if (licenceKey() && isCouponApplied() && !billingAddress) {
            setHide3(true);
            setHide2(false);
            setHide1(true);
          } else if (isCouponApplied()) {
            if (!disableCouponApply) {
              isCouponApplied() && setHide1(true);
              isCouponApplied() && setHide2(false);
              isCouponApplied() && setHide3(true);
            }
          }
          if (disableCouponApply) {
            isCouponApplied() && setHide1(true);
          }
        } else {
          setHide3(true);
          setHide2(false);
          setHide1(true);
        }
        break;
      case '2':
        setHide1(true);
        setHide2(true);
        setHide3(false);
        break;
      case '3':
        setHide1(true);
        setHide2(false);
        setHide3(false);
        break;
      default:
        break;
    }
  };

  const previous = (value) => {
    switch (value) {
      case '1':
        setHide2(false);
        setHide1(true);
        setHide3(true);
        break;
      case '2':
        setHide1(false);
        setHide2(true);
        setHide3(true);
        break;
      case '3':
        if (licenceKey() && isCouponApplied() && (billingAddress || last4)) {
          setHide1(false);
          setHide2(true);
        } else if (licenceKey() && isCouponApplied() && !billingAddress) {
          setHide1(true);
          setHide2(false);
        } else {
          setHide1(true);
          setHide2(false);
        }
        setHide3(true);
        break;
      default:
        break;
    }
  };
  const [paymentRequest, setPaymentRequest] = useState(null);
  useEffect(() => {
    let finalAmount =
      parseFloat(getPayableAmount()) + parseFloat(getTax()) < 0
        ? 0
        : (parseFloat(getPayableAmount()) + parseFloat(getTax())).toFixed(2);
    if (paymentRequest)
      paymentRequest.update({
        total: {
          label: 'LRT Amount',
          amount: Math.floor(finalAmount * 100),
        },
      });
    // eslint-disable-next-line
  }, [amount, currency.amount, getPayableAmount(), getTax()]);

  let setPaymentMethod = () => {
    // const headers = {
    //   Authorization: `Bearer ${cookies.get('access_token')}`,
    //   'x-user-uuid': `${cookies.get('user_session')}`,
    //   'Content-Type': 'application/json',
    // };
    if (paymentRequest)
      paymentRequest.on('paymentmethod', async (e) => {
        let data = {
          interval: interval.interval,
          plan_id: interval.id,
          region: 'Europe',
          service_type: match.url.includes('lrt') ? 'lrt' : 'soloconnect',
          tax_percent: tax,
          unit_id: match.params.bossId,
          paymentId: e.paymentMethod.id,
          coupon: coupon,
          scnumber: scnumber,
          kc: kitConfig,
        };
        addCard('', data, '', true).then(() => {
          e.complete('success');
          history.push('/dashboard');
        });
        // let finalAmount =
        //     parseFloat(getPayableAmount()) + parseFloat(getTax()) < 0
        //         ? 0
        //         : (parseFloat(getPayableAmount()) + parseFloat(getTax())).toFixed(2);
        // const { error: backendError, clientSecret } = await fetch(
        //   `${REACT_APP_AWSServerURL}/stripepaymentbutton`,
        //   {
        //     method: 'POST',
        //     headers: headers,
        //     body: JSON.stringify({
        //       paymentMethodType: 'card',
        //       currency: 'usd',
        //       amount: Math.floor(finalAmount * 100),
        //     }),
        //   },
        // ).then((r) => r.json());
        // await paymentActions.paymentButton({
        //   paymentMethodType: 'card',
        //   currency: 'usd',
        //   amount: amount * currency.amount,
        // });

        // if (backendError) {
        //   e.complete('fail');
        //   return;
        // }
        // const {
        //   error: stripeError,
        //   // paymentIntent,
        // } = await stripe.confirmCardPayment(
        //   clientSecret,
        //   {
        //     payment_method: e.paymentMethod.id,
        //   },
        //   { handleActions: false },
        // );

        // if (stripeError) {
        //   notify('error', 'insufficient funds');
        //   e.complete('fail');
        //   return;
        // }
        // e.complete('success');
        // notify('success', 'payment completed');
        // history.push('/dashboard');
      });
  };

  //payment buttons
  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    let finalAmount =
      parseFloat(getPayableAmount()) + parseFloat(getTax()) < 0
        ? 0
        : (parseFloat(getPayableAmount()) + parseFloat(getTax())).toFixed(2);
    let pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'LRT Amount',
        amount: finalAmount * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });
    pr.on('cancel', async () => {
      pr.removeEventListener('paymentmethod');
    });
    // eslint-disable-next-line
  }, [stripe, elements /*, addMessage*/]);

  function checkCountry(item) {
    var countries = countryList;
    const result = filter(countries, function (o) {
      return item.label === o.label || item.value === o.value;
    });
    return result.length ? result[0] : [];
  }
  function checkUsState(item) {
    var usStates = usStateList;
    const result = filter(usStates, function (o) {
      return item.label === o.label || item.value === o.value;
    });
    return result.length ? result[0] : [];
  }
  function checkCanadaState(item) {
    var canadaStates = canadaStateList;
    const result = filter(canadaStates, function (o) {
      return item.label === o.label || item.value === o.value;
    });
    return result.length ? result[0] : [];
  }

  const handlePlanHeading = () => {
    if (match.url.includes('soloconnect')) {
      return <span className='h2Title f-size-16'>{t('SOLOCONNECTPLAN')}</span>;
    }

    if (match.url.includes('lrt')) {
      return <span className='h2Title f-size-16'>{t('LRTPLAN')}</span>;
    }

    if (match.url.includes('streamtool')) {
      return <span className='h2Title f-size-16'>{t('STPLAN')}</span>;
    }
  };
  const isCheckModemRegion = () => {
    return (
      parseInt(kitConfig) === 2 &&
      unit?.unit?.product !== 'LU_SOLO_PRO' &&
      state?.region === 'ROW'
    );
  };

  return (
    <div className='lrtpayment clearfix'>
      <div className='container-min'>
        <div className='sub-title border-0 mb-0'>
          <h4>{t('PAYMENT')}</h4>
        </div>
        <div className='lrtpayment_blk'>
          <div className='card card-default credit-card-box'>
            <div className='card-body'>
              <Formik
                initialValues={{
                  fullname: userFormData?.fullname
                    ? userFormData?.fullname
                    : '',
                  cvc: userFormData?.cvc ? userFormData?.cvc : '',
                  number: userFormData?.number ? userFormData?.number : '',
                  expiry: userFormData?.expiry ? userFormData?.expiry : '',
                  cardnumber: userFormData?.cardnumber
                    ? userFormData?.cardnumber
                    : customerCardDetails
                    ? useNewCard
                      ? ''
                      : customerCardDetails.last4
                    : '',
                  bname: userFormData?.bname
                    ? userFormData?.bname
                    : customerAddress.bname,
                  address1: userFormData?.address1
                    ? userFormData?.address1
                    : customerAddress.address1,
                  address2: userFormData?.address2
                    ? userFormData?.address2
                    : customerAddress.address2,
                  city: userFormData?.city
                    ? userFormData.city
                    : customerAddress.city,
                  countryCode: userFormData?.countryCode
                    ? userFormData?.countryCode
                    : customerAddress.country,
                  regionDropDown: userFormData?.regionDropDown
                    ? userFormData?.regionDropDown
                    : regionValue2
                    ? regionValue2
                    : '',
                  usstate: userFormData?.usstate
                    ? userFormData?.usstate
                    : customerAddress.country === 'United States' ||
                      customerAddress.country === 'US'
                    ? checkUsState({
                        label: customerAddress.state,
                        value: customerAddress.state,
                      })
                    : [],
                  canadastate: userFormData?.canadastate
                    ? userFormData?.canadastate
                    : customerAddress.country === 'Canada' ||
                      customerAddress.country === 'CA'
                    ? checkCanadaState({
                        label: customerAddress.state,
                        value: customerAddress.state,
                      })
                    : [],
                  state: '',
                  otherstate: userFormData?.otherstate
                    ? userFormData?.otherstate
                    : customerAddress.country !== 'United States' &&
                      customerAddress.country !== 'US' &&
                      customerAddress.country !== 'Canada' &&
                      customerAddress.country !== 'CA'
                    ? customerAddress.state
                      ? customerAddress.state
                      : ''
                    : '',
                  zipcode: userFormData?.zipcode
                    ? userFormData?.zipcode
                    : customerAddress.zipcode
                    ? customerAddress.zipcode
                    : '',
                  country: userFormData?.country
                    ? userFormData?.country
                    : customerAddress.country
                    ? checkCountry({
                        label: customerAddress && customerAddress.country,
                        value: customerAddress && customerAddress.country,
                      })
                    : '',
                  customerCardDetails: customerCardDetails,
                  tnc_checked: userFormData?.tnc_checked
                    ? userFormData?.tnc_checked
                    : false,
                }}
                validationSchema={Yup.object({
                  customerCardDetails: Yup.object(),
                  fullname: Yup.string()
                    .trim()
                    .when('customerCardDetails', {
                      is: customerCardDetails,
                      then:
                        licenceKey() && isCouponApplied()
                          ? Yup.string().notRequired()
                          : isIndianCustomer
                          ? Yup.string().notRequired()
                          : Yup.string().required(t('FULLNAMEREQUIRED')),
                      otherwise: useNewCard
                        ? Yup.string().required(t('FULLNAMEREQUIRED'))
                        : Yup.string().notRequired(),
                    }),
                  cardnumber: Yup.string(),
                  number: Yup.string()
                    .trim()
                    .when('customerCardDetails', {
                      is: customerCardDetails,
                      then:
                        licenceKey() && isCouponApplied()
                          ? Yup.string().notRequired()
                          : isIndianCustomer
                          ? Yup.string().notRequired()
                          : Yup.string().required(t('CCREQUIRED')),
                      otherwise: useNewCard
                        ? Yup.string().required(t('CCREQUIRED'))
                        : Yup.string().notRequired(),
                    }),
                  expiry: Yup.string()
                    .trim()
                    .when('customerCardDetails', {
                      is: customerCardDetails,
                      then:
                        licenceKey() && isCouponApplied()
                          ? Yup.string().notRequired()
                          : isIndianCustomer
                          ? Yup.string().notRequired()
                          : Yup.string().required(t('EXPIRYDATEREQUIRED')),
                      otherwise: useNewCard
                        ? Yup.string().required(t('EXPIRYDATEREQUIRED'))
                        : Yup.string().notRequired(),
                    }),
                  cvc: Yup.string()
                    .trim()
                    .when('customerCardDetails', {
                      is: customerCardDetails,
                      then:
                        licenceKey() && isCouponApplied()
                          ? Yup.string().notRequired()
                          : isIndianCustomer
                          ? Yup.string().notRequired()
                          : Yup.string().required(t('CVCREQUIRED')),
                      otherwise: useNewCard
                        ? Yup.string().required(t('CVCREQUIRED'))
                        : Yup.string().notRequired(),
                    })
                    .min(3, t('INVALIDCVC'))
                    .max(4, t('INVALIDCVC')),
                  bname: Yup.string().trim(),
                  address1: Yup.string()
                    .trim()
                    .when('customerCardDetails', {
                      is: customerCardDetails,
                      then:
                        licenceKey() && isCouponApplied()
                          ? Yup.string().notRequired()
                          : Yup.string().required(t('ADDRESSREQUIRED')),
                      otherwise: Yup.string().required(),
                    }),
                  address2: Yup.string().trim(),
                  city: Yup.string()
                    .trim()
                    .when('customerCardDetails', {
                      is: customerCardDetails,
                      then:
                        licenceKey() && isCouponApplied()
                          ? Yup.string().notRequired()
                          : Yup.string().required(t('CITYREQUIRED')),
                      otherwise: Yup.string().required(),
                    }),
                  regionDropDown: Yup.object()
                    .shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                    .nullable(),
                  zipcode: Yup.string()
                    .matches(/^\w{1,}(?:[-\s]\w{1,})?$/, 'Zipcode is invalid')
                    .trim()
                    .when('customerCardDetails', {
                      is: customerCardDetails,
                      then:
                        licenceKey() && isCouponApplied()
                          ? Yup.string().notRequired()
                          : Yup.string().required(t('ZIPCODEREQUIRED')),
                      otherwise: Yup.string().required(),
                    }),
                  countryCode: Yup.string(),
                  state: Yup.string(),
                  otherstate: Yup.string()
                    .trim()
                    .when('customerCardDetails', {
                      is: customerCardDetails,
                      then: Yup.string().when('countryCode', {
                        // eslint-disable-next-line no-unused-vars
                        is: (countryCode) => 'US' || 'CA',
                        then: Yup.string().notRequired(),
                        otherwise:
                          licenceKey() && isCouponApplied()
                            ? Yup.string().notRequired()
                            : Yup.string().required(t('STATEREQUIRED')),
                      }),
                      otherwise: Yup.string().notRequired(),
                    }),
                  usstate: Yup.string().when('customerCardDetails', {
                    is: customerCardDetails,
                    then: Yup.string().when('countryCode', {
                      is: 'US',
                      then:
                        licenceKey() && isCouponApplied()
                          ? Yup.string().notRequired()
                          : Yup.string().required(t('STATEREQUIRED')),
                      otherwise: Yup.string().notRequired(),
                    }),
                    otherwise: Yup.string().notRequired(),
                  }),
                  canadastate: Yup.string().when('customerCardDetails', {
                    is: customerCardDetails,
                    then: Yup.string().when('countryCode', {
                      is: 'CA',
                      then:
                        licenceKey() && isCouponApplied()
                          ? Yup.string().notRequired()
                          : Yup.string().required(t('STATEREQUIRED')),
                      otherwise: Yup.string().notRequired(),
                    }),
                    otherwise: Yup.string().notRequired(),
                  }),
                  country: Yup.string().when('customerCardDetails', {
                    is: customerCardDetails,
                    then:
                      licenceKey() && isCouponApplied()
                        ? Yup.string().notRequired()
                        : Yup.string().required(t('SELECTCOUNTRY')),
                    otherwise: Yup.string().required(),
                  }),
                  tnc_checked: Yup.boolean(),
                })}
                // eslint-disable-next-line no-unused-vars
                onSubmit={(values, { setSubmitting }) => {
                  values.fullname = values.fullname
                    ? values.fullname.trim()
                    : '';
                  values.bname = values.bname ? values.bname.trim() : '';
                  values.address1 = values.address1
                    ? values.address1.trim()
                    : '';
                  values.address2 = values.address2
                    ? values.address2.trim()
                    : '';
                  values.city = values.city ? values.city.trim() : '';
                  values.state =
                    values.countryCode === 'US'
                      ? values.usstate.value
                      : values.countryCode === 'CA'
                      ? values.canadastate.value
                      : values.otherstate.trim();
                  values.zipcode = values.zipcode ? values.zipcode.trim() : '';
                  // values.regionCountry = values.regionCountry
                  //   ? values.regionCountry.value
                  //   : '';
                  // values.regionDropDown = values.regionDropDown
                  //   ? values.regionDropDown.value
                  //   : '';
                  if ((licenceKey() && isCouponApplied()) || isIndianCustomer) {
                    onSubmitHandler(null, values);
                  } else {
                    if (customerCardDetails) {
                      if (useNewCard) {
                        createStripeToken(values);
                      } else {
                        onSubmitHandler(null, values);
                      }
                    } else {
                      createStripeToken(values);
                    }
                  }
                }}
                enableReinitialize={true}
              >
                {(formik) => (
                  <form
                    onSubmit={formik.handleSubmit}
                    className='form-horizontal w-100'
                    id='payment-form'
                    name='paymentForm'
                    role='form'
                  >
                    <div
                      className={`tabCont ${
                        hide1 ? 'multipartform-1-hide' : 'multipartform-1-show'
                      }`}
                    >
                      <div className='tabDetails align-items-center d-flex flex-column'>
                        <div className='w-100 text-center h5 font-weight-bold pb-2 border-bottom'>
                          {t('SUBSCRIPTIONSTEP1')}
                        </div>
                        <form>
                          <div className=' mb-3 '>
                            <div
                              className={`d-flex justify-content-between mt-3`}
                            >
                              <div className={'lrtplan '}>
                                {getPlanButton()}
                              </div>
                              {unit?.unit?.product === 'LU_SOLO_PRO' &&
                              match.url.includes('soloconnect') ? (
                                <div className='notesTxt text-center'>
                                  <i
                                    className='fa fa-info-circle'
                                    aria-hidden='true'
                                  ></i>{' '}
                                  {t('CHOOSEREGION')}
                                </div>
                              ) : (
                                <div className='text-right'>
                                  {t('COST')} :
                                  <b>
                                    {cookies.get('currency_symbol')}
                                    {(amount / 100) * currency.amount}
                                  </b>
                                </div>
                              )}
                            </div>

                            <div className='mt-5 promocode w-100 clearfix'>
                              <div className='row'>
                                {!isCouponApplied() && (
                                  <div className='col-sm-12 lbltxt'>
                                    {t('ENTERPROMOCODE')}
                                    {/* <span className="fa fa-info-circle fa-lg text-info payment-tooltip" data-toggle="tooltip">
                                                            <span className="tooltiptext">{t('LICENSEINFO')}</span>
                                                        </span> */}
                                  </div>
                                )}
                                {isCouponApplied() && (
                                  <div className='col-sm-12 lbltxt'>
                                    {t('COUPONAPPLY')}
                                  </div>
                                )}
                                <div className='col-sm-12'>
                                  <div className='row'>
                                    <input
                                      className='form-control success col-sm-9 col-9'
                                      maxLength='30'
                                      name='coupon'
                                      value={coupon}
                                      disabled={isCouponApplied()}
                                      placeholder={t('COUPONPLACEHOLDER')}
                                      onChange={onCouponChangeHandler}
                                    />
                                    {!isCouponApplied() && (
                                      <React.Fragment>
                                        {
                                          <button
                                            type='button'
                                            className='btn btn-primary col-sm-3 col-3'
                                            id='applycouponbutton'
                                            onClick={() => {
                                              setNewCard(false);
                                              applycoupon();
                                            }}
                                            disabled={
                                              !coupon || disableCouponApply
                                            }
                                          >
                                            {!disableCouponApply ? (
                                              t('APPLY')
                                            ) : (
                                              <ClipLoader
                                                size={15}
                                                title='loading'
                                                color={'#FFF'}
                                                loading={true}
                                              />
                                            )}
                                          </button>
                                        }
                                        {restrictKey && (
                                          <span className='txt-sm restrict-licence-key mt-2'>
                                            {t(`${restrictKey}`)}
                                          </span>
                                        )}
                                      </React.Fragment>
                                    )}
                                    {isCouponApplied() && (
                                      <button
                                        className='btn btn-primary col-3'
                                        onClick={removeCouponHandler}
                                        type='submit'
                                      >
                                        {t('CANCEL')}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {match.url.includes('soloconnect') &&
                                !restrictKey && (
                                  <div className='row reseller-code-msg'>
                                    <div className='col-sm-12'>
                                      {t('RESELLERCODE')}
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className='text-center'>
                              <span className='font-italic text-muted'>
                                {t('LICENSEINFO')}
                              </span>
                              <br />
                              <span className='font-italic text-muted'>
                                {t('PRESSNEXTWITHOUTCOUPON')}
                              </span>
                            </div>
                          </div>
                        </form>

                        <div className=''>&nbsp;</div>
                      </div>
                      <div className='tabFootNav'>
                        <div className='next-previous-btn justify-content-end'>
                          <button
                            className='btn btn-primary  btn-sm'
                            id='paybutton'
                            disabled={disableCouponApply}
                            onClick={() => {
                              coupon && !isCouponApplied() && applycoupon();
                              next('1');
                              if (
                                customerAddress.country &&
                                (customerAddress.country === 'US' ||
                                  customerAddress.country === 'United State') &&
                                customerAddress.zipcode &&
                                !licenceKey() &&
                                customerDetails.sources.data.length === 0
                              ) {
                                countryChange(
                                  formik.values.country,
                                  formik.values.zipcode,
                                );
                              }
                            }}
                            type='button'
                          >
                            {t('NEXT')}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tabCont ${
                        hide2 ? 'multipartform-2-hide' : 'multipartform-2-show'
                      }`}
                    >
                      <div className='tabDetails d-flex flex-column'>
                        <div className='text-center h5 font-weight-bold pb-2 border-bottom'>
                          {t('SUBSCRIPTIONSTEP2')}
                        </div>
                        <form>
                          <div className=' mb-3 '>
                            <div className='d-flex justify-content-between mt-3'>
                              <div className={'lrtplan'}>{getPlanButton()}</div>
                              {unit?.unit?.product === 'LU_SOLO_PRO' &&
                              match.url.includes('soloconnect') ? (
                                <div className='notesTxt text-center'>
                                  <i
                                    className='fa fa-info-circle'
                                    aria-hidden='true'
                                  ></i>{' '}
                                  {t('CHOOSEREGION')}
                                </div>
                              ) : (
                                <div className='text-right'>
                                  {t('COST')} :
                                  <b>
                                    {cookies.get('currency_symbol')}
                                    {(amount / 100) * currency.amount}
                                  </b>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                        {useNewCard && (
                          <React.Fragment>
                            {licenceKey() && isCouponApplied() ? (
                              <>
                                <div className='form-group row disabledbutton'>
                                  <div className='col-sm-4 lbltxt'>
                                    {t('FULLNAME')}
                                    <span className='red'>*</span>
                                  </div>
                                  <div className='col-sm-8'>
                                    <input
                                      // className={`form-control ${
                                      //   formik.errors.fullname ||
                                      //   (!regex.test(formik.values.fullname) &&
                                      //     formik.values.fullname === '')
                                      //     ? 'success'
                                      //     : ''
                                      // }`}
                                      className={
                                        !!formik.values.fullname
                                          ? 'form-control success'
                                          : 'form-control'
                                      }
                                      maxLength='40'
                                      name='fullname'
                                      type='text'
                                      {...formik.getFieldProps('fullname')}
                                    />
                                    <span className='txt-sm'>
                                      {t('NAMEASONCARD')}
                                    </span>
                                    <br />
                                    {!regex.test(formik.values.fullname) &&
                                      formik.values.fullname !== '' && (
                                        <>
                                          <span className='col-sm-3 error'>
                                            {t('INCORRECTFULLNAME')}
                                          </span>
                                        </>
                                      )}
                                  </div>
                                </div>
                                <div className='form-group row disabledbutton'>
                                  <div className='col-sm-4 lbltxt'>
                                    {t('CREDITCARDNUMBER')}
                                    <span className='red'>*</span>
                                  </div>
                                  <div className='col-sm-8'>
                                    <div
                                      className='cardTypeSec '
                                      data-type={brandType}
                                    >
                                      <CardNumberElement
                                        className={`form-control ${
                                          formik.errors.number ? '' : 'success'
                                        }`}
                                        onChange={(event) => {
                                          setBrandType(event.brand);
                                          event.complete
                                            ? formik.setFieldValue(
                                                'number',
                                                '2324',
                                              )
                                            : formik.setFieldValue(
                                                'number',
                                                '',
                                              );
                                        }}
                                      />
                                    </div>
                                    <ErrorMessage
                                      component='span'
                                      name='number'
                                      className='col-sm-3 error'
                                    />
                                  </div>
                                </div>
                                <div className='form-group row disabledbutton'>
                                  <div className='col-sm-4 lbltxt'>
                                    {t('EXPIRYDATE')}
                                    <span className='red'>*</span>
                                  </div>
                                  <div className='col-sm-3'>
                                    <CardExpiryElement
                                      className={`form-control ${
                                        formik.errors.expiry ? '' : 'success'
                                      }`}
                                      onChange={(event) => {
                                        event.complete
                                          ? formik.setFieldValue(
                                              'expiry',
                                              '2324',
                                            )
                                          : formik.setFieldValue('expiry', '');
                                      }}
                                    />
                                    <ErrorMessage
                                      component='span'
                                      name='expiry'
                                      className='col-sm-2 error'
                                    />
                                  </div>
                                  <div className='col-sm-2 lbltxt text-sm-right mt-sm-0 mt-3'>
                                    {t('CVC')}
                                    <span className='red'>*</span>
                                  </div>
                                  <div className='col-sm-3'>
                                    <CardCvcElement
                                      className={`form-control ${
                                        formik.errors.cvc ? '' : 'success'
                                      }`}
                                      onChange={(event) => {
                                        event.complete
                                          ? formik.setFieldValue('cvc', '2324')
                                          : formik.setFieldValue('cvc', '');
                                      }}
                                    />
                                    <ErrorMessage
                                      component='span'
                                      name='cvc'
                                      className='col-sm-2 error'
                                    />
                                  </div>
                                </div>
                                <div className='form-group row disabledbutton'>
                                  <div className='col-sm-4 lbltxt'>
                                    {t('BUSINESSNAME')}
                                  </div>
                                  <div className='col-sm-8'>
                                    <input
                                      className={`form-control ${
                                        formik.errors.fullname ||
                                        (!regex2.test(formik.values.bname) &&
                                          formik.values.bname !== '')
                                          ? ''
                                          : 'success'
                                      }`}
                                      maxLength='80'
                                      name='bname'
                                      type='text'
                                      {...formik.getFieldProps('bname')}
                                    />
                                    {!regex2.test(formik.values.bname) &&
                                      formik.values.bname !== '' && (
                                        <span className='error'>
                                          {t('INCORRECTBUSINESSNAME')}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='form-group row'>
                                  <div className='col-sm-4 lbltxt'>
                                    {t('FULLNAME')}
                                    <span className='red'>*</span>
                                  </div>
                                  <div className='col-sm-8'>
                                    <input
                                      // className={`form-control ${
                                      //   !!formik.errors.fullname ||
                                      //   (!regex.test(formik.values.fullname) &&
                                      //     formik.values.fullname)
                                      //     ? ''
                                      //     : 'success'
                                      // }`}
                                      className={
                                        !!formik.values.fullname ||
                                        (!regex.test(formik.values.fullname) &&
                                          formik.values.fullname !== '')
                                          ? 'form-control success'
                                          : 'form-control'
                                      }
                                      maxLength='40'
                                      name='fullname'
                                      type='text'
                                      {...formik.getFieldProps('fullname')}
                                    />
                                    <span className='txt-sm'>
                                      {t('NAMEASONCARD')}
                                    </span>
                                    <br />
                                    {!regex.test(formik.values.fullname) &&
                                      formik.values.fullname !== '' && (
                                        <>
                                          <span className='col-sm-3 error'>
                                            {t('INCORRECTFULLNAME')}
                                          </span>
                                        </>
                                      )}
                                  </div>
                                </div>
                                <div className='form-group row'>
                                  <div className='col-sm-4 lbltxt'>
                                    {t('CREDITCARDNUMBER')}
                                    <span className='red'>*</span>
                                  </div>
                                  <div className='col-sm-8'>
                                    <div
                                      className='cardTypeSec '
                                      data-type={brandType}
                                    >
                                      <CardNumberElement
                                        // className={`form-control ${
                                        //   formik.errors.number ? '' : 'success'
                                        // }`}
                                        className={
                                          !!formik.values.number ||
                                          formik.values.number !== ''
                                            ? 'form-control success'
                                            : 'form-control'
                                        }
                                        onChange={(event) => {
                                          setBrandType(event.brand);
                                          event.complete
                                            ? formik.setFieldValue(
                                                'number',
                                                '2324',
                                              )
                                            : formik.setFieldValue(
                                                'number',
                                                '',
                                              );
                                        }}
                                      />
                                    </div>
                                    <ErrorMessage
                                      component='span'
                                      name='number'
                                      className='col-sm-3 error'
                                    />
                                  </div>
                                </div>
                                <div className='form-group row'>
                                  <div className='col-sm-4 lbltxt'>
                                    {t('EXPIRYDATE')}
                                    <span className='red'>*</span>
                                  </div>
                                  <div className='col-sm-3'>
                                    <CardExpiryElement
                                      // className={`form-control ${
                                      //   formik.errors.expiry ? '' : 'success'
                                      // }`}
                                      className={
                                        !!formik.values.expiry ||
                                        formik.values.expiry !== ''
                                          ? 'form-control success'
                                          : 'form-control'
                                      }
                                      onChange={(event) => {
                                        event.complete
                                          ? formik.setFieldValue(
                                              'expiry',
                                              '2324',
                                            )
                                          : formik.setFieldValue('expiry', '');
                                      }}
                                    />
                                    <ErrorMessage
                                      component='span'
                                      name='expiry'
                                      className='col-sm-2 error'
                                    />
                                  </div>
                                  <div className='col-sm-2 lbltxt text-sm-right mt-sm-0 mt-3'>
                                    {t('CVC')}
                                    <span className='red'>*</span>
                                  </div>
                                  <div className='col-sm-3'>
                                    <CardCvcElement
                                      // className={`form-control ${
                                      //   formik.errors.cvc ? '' : 'success'
                                      // }`}
                                      className={
                                        !!formik.values.cvc ||
                                        formik.values.cvc !== ''
                                          ? 'form-control success'
                                          : 'form-control'
                                      }
                                      onChange={(event) => {
                                        event.complete
                                          ? formik.setFieldValue('cvc', '2324')
                                          : formik.setFieldValue('cvc', '');
                                      }}
                                    />
                                    <ErrorMessage
                                      component='span'
                                      name='cvc'
                                      className='col-sm-2 error'
                                    />
                                  </div>
                                </div>
                                <div className='form-group row'>
                                  <div className='col-sm-4 lbltxt'>
                                    {t('BUSINESSNAME')}
                                  </div>
                                  <div className='col-sm-8'>
                                    <input
                                      className='form-control success'
                                      maxLength='80'
                                      name='bname'
                                      type='text'
                                      {...formik.getFieldProps('bname')}
                                    />
                                    {!regex2.test(formik.values.bname) &&
                                      formik.values.bname !== '' && (
                                        <span className='error'>
                                          {t('INCORRECTBUSINESSNAME')}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </>
                            )}
                            <div className='form-group row'>
                              <div className='col-sm-4 lbltxt'>
                                {t('ADDRESSLINE1')}
                                <span className='red'>*</span>
                              </div>
                              <div className='col-sm-8'>
                                <input
                                  // className={`form-control ${
                                  //   formik.errors.address1 ||
                                  //   formik.values.address1 === '' ||
                                  //   !regex2.test(formik.values.address1)
                                  //     ? ''
                                  //     : 'success'
                                  // }`}
                                  className={
                                    !!formik.values.address1 ||
                                    (!regex2.test(formik.values.address1) &&
                                      formik.values.address1 !== '')
                                      ? 'form-control success'
                                      : 'form-control'
                                  }
                                  maxLength='80'
                                  name='address1'
                                  type='text'
                                  {...formik.getFieldProps('address1')}
                                />
                                <ErrorMessage
                                  component='span'
                                  name='address1'
                                  className='col-sm-8 error'
                                />
                                {!regex2.test(formik.values.address1) &&
                                  formik.values.address1 !== '' && (
                                    <>
                                      <span className='col-sm-3 error'>
                                        {t('INCORRECTADDRESSLINE')}
                                      </span>
                                    </>
                                  )}
                              </div>
                            </div>
                            <div className='form-group row'>
                              <div className='col-sm-4 lbltxt'>
                                {t('ADDRESSLINE2')}
                              </div>
                              <div className='col-sm-8'>
                                <input
                                  className={`form-control ${
                                    !regex2.test(formik.values.address2) &&
                                    formik.values.address2 !== ''
                                      ? ''
                                      : 'success'
                                  }`}
                                  name='address2'
                                  type='text'
                                  {...formik.getFieldProps('address2')}
                                />
                                {!regex2.test(formik.values.address2) &&
                                  formik.values.address2 !== '' && (
                                    <span className='error'>
                                      {t('INCORRECTADDRESSLINE')}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className='form-group row'>
                              <div className='col-sm-4 lbltxt'>
                                {t('CITY')}
                                <span className='red'>*</span>
                              </div>
                              <div className='col-sm-3'>
                                <input
                                  className={`form-control ${
                                    formik.errors.city ||
                                    formik.values.city === '' ||
                                    !regex2.test(formik.values.city)
                                      ? ''
                                      : 'success'
                                  }`}
                                  name='city'
                                  type='text'
                                  {...formik.getFieldProps('city')}
                                />
                                <ErrorMessage
                                  component='span'
                                  name='city'
                                  className='col-sm-8 error'
                                />
                                {!regex2.test(formik.values.city) &&
                                  formik.values.city !== '' && (
                                    <>
                                      <span className='error'>
                                        {t('INCORRECTCITY')}
                                      </span>
                                    </>
                                  )}
                              </div>
                              <div className='col-sm-2 lbltxt nowrap'>
                                {t('ZIPCODE')}
                                <span className='red'>*</span>
                              </div>
                              <div className='col-sm-3 zipCode'>
                                <input
                                  // className={`form-control ${
                                  //   formik.errors.zipcode ||
                                  //   formik.values.zipe === '' ||
                                  //   (formik.values.country.value === 'US' &&
                                  //     !isZipValid)
                                  //     ? ''
                                  //     : 'success'
                                  // }`}
                                  className={
                                    regexZipcode.test(formik.values.zipcode)
                                      ? 'form-control success'
                                      : 'form-control'
                                  }
                                  maxLength='10'
                                  name='zipcode'
                                  onKeyUp={(e) => {
                                    if (!!e.target && !!e.target.value) {
                                      countryChange(
                                        formik.values.country,
                                        formik.values.zipcode,
                                      );
                                    }
                                    //formik.setFieldValue("zipcode", e.target.value);
                                  }}
                                  type='text'
                                  {...formik.getFieldProps('zipcode')}
                                />
                                <ErrorMessage
                                  component='span'
                                  name='zipcode'
                                  className='col-sm-2 error'
                                />
                                {/* <Field
                                                            className={`form-control ${(formik.errors.zipcode || (formik.values.country.value === "US" && !isZipValid)) ? '' : 'success'}`}
                                                            type="text"
                                                            name="zipcode"
                                                            onKeyUp={e => {
                                                                console.log('here', !!e.target, e.target)
                                                                if (!!e.target && !!e.target.value) {
                                                                    countryChange(formik.values.country, formik.values.zipcode)
                                                                }
                                                                formik.setFieldValue("zipcode", e.target.value);
                                                            }}
                                                        /> */}
                              </div>
                            </div>
                            <div className='form-group row'>
                              <div className='col-sm-4 lbltxt'>
                                {t('COUNTRY')}
                                <span className='red'>*</span>
                              </div>
                              <div className='col-sm-8'>
                                <CountryDropdown
                                  name='country'
                                  value={formik.values.country}
                                  handleCountryChange={(value) => {
                                    formik.setFieldValue('country', value);
                                    formik.values.country = value;
                                    countryChange(value, formik.values.zipcode);
                                    formik.setFieldValue(
                                      'countryCode',
                                      value.value,
                                    );
                                    formik.values.countryCode = value.value;
                                  }}
                                  onblur={() => {
                                    formik.setFieldTouched('country', true);
                                  }}
                                />
                                <ErrorMessage
                                  component='span'
                                  name='country'
                                  className='col-sm-2 error'
                                />
                              </div>
                            </div>
                            {!!formik.values.country.label && (
                              <div className='form-group row'>
                                <div className='col-sm-4 lbltxt'>
                                  {t('STATE')}
                                  <span className='red'>*</span>
                                </div>
                                <div className='col-sm-8'>
                                  {formik.values.country.value === 'US' ? (
                                    <React.Fragment>
                                      {' '}
                                      <UsStateDropdown
                                        name='usstate'
                                        value={formik.values.usstate}
                                        handleStateChange={(value) => {
                                          formik.setFieldValue(
                                            'usstate',
                                            value,
                                          );
                                          formik.values.usstate = value;
                                        }}
                                        onblur={() => {
                                          formik.setFieldTouched(
                                            'usstate',
                                            true,
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        component='span'
                                        name='usstate'
                                        className='error'
                                      />
                                    </React.Fragment>
                                  ) : formik.values.country.value === 'CA' ? (
                                    <React.Fragment>
                                      {' '}
                                      <UsStateDropdown
                                        name='canadastate'
                                        value={formik.values.canadastate}
                                        handleStateChange={(value) => {
                                          formik.setFieldValue(
                                            'canadastate',
                                            value,
                                          );
                                          formik.values.canadastate = value;
                                        }}
                                        onblur={() => {
                                          formik.setFieldTouched(
                                            'canadastate',
                                            true,
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        component='span'
                                        name='canadastate'
                                        className='error'
                                      />
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <input
                                        className={`form-control ${
                                          formik.errors.otherstate
                                            ? ''
                                            : 'success'
                                        }`}
                                        name='otherstate'
                                        type='text'
                                        {...formik.getFieldProps('otherstate')}
                                      />
                                      <ErrorMessage
                                        component='span'
                                        name='otherstate'
                                        className='col-sm-3 error'
                                      />
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                            )}
                            {(customerCardDetails || useNewCard) && (
                              <div className='text-center'>
                                {' '}
                                <Link to='#' onClick={() => setNewCard(false)}>
                                  {t('USEEXISTCARD')}
                                </Link>
                              </div>
                            )}
                          </React.Fragment>
                        )}
                        {!customerCardDetails &&
                          !useNewCard &&
                          !isIndianCustomer && (
                            <div
                              className='cardDesign emptyCard'
                              onClick={() => {
                                setNewCard(true);
                                setPaymentMethodSelect('card');
                              }}
                            >
                              <div className='plus'>+</div>
                            </div>
                          )}

                        {customerCardDetails &&
                          !useNewCard &&
                          !isIndianCustomer && (
                            <>
                              <div className='text-center pb-3'>
                                {' '}
                                <Link
                                  to='#'
                                  onClick={() => {
                                    setNewCard(true);
                                    setPaymentMethodSelect('card');
                                  }}
                                >
                                  {t('USENEWCARD')}
                                </Link>
                              </div>
                              <label className='cardDesign chackBoxGroup'>
                                <input
                                  type={'radio'}
                                  name={'paymentSelect'}
                                  value={'card'}
                                  onClick={() => setPaymentMethodSelect('card')}
                                  checked={!!customerCardDetails}
                                />
                                <span className='chackBox' />
                                <div className='selectCard' />
                                <div className='cardDetails_sec'>
                                  <div className='cardNumber'>
                                    {t('CARDLASTDIGIT')}{' '}
                                    {customerCardDetails.last4}{' '}
                                  </div>
                                  <div className='cardExp'>
                                    {customerCardDetails.exp_month}/
                                    {customerCardDetails.exp_year}
                                  </div>
                                  <div className='d-flex justify-content-between'>
                                    <div className='cardHolder'>
                                      {customerCardDetails.name}
                                    </div>
                                    <div
                                      className={`cardType ${customerCardDetails.brand.toLowerCase()}`}
                                    />
                                  </div>
                                </div>
                              </label>
                            </>
                          )}
                        {isIndianCustomer && (
                          <div className='text-center py-2'>
                            <div className='sendInvoiceMailBox'>
                              <label
                                className='stripButton chackBoxGroup sendInvoiceMailBtn'
                                onClick={() => setPaymentMethodSelect('card')}
                              >
                                <input
                                  type={'radio'}
                                  name={'paymentSelect'}
                                  value={'paymentButton'}
                                  checked={
                                    paymentMethodSelect !== 'paymentButton'
                                  }
                                />
                                <span className='chackBox'></span>
                                <span className='text'>
                                  {t('SENDINVOICEONMAIL')}
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                        {paymentRequest && !useNewCard && (
                          <div className='text-center py-2'>Or Pay with</div>
                        )}
                        {paymentRequest && !useNewCard && (
                          <label
                            className='stripButton chackBoxGroup'
                            onClick={() =>
                              setPaymentMethodSelect('paymentButton')
                            }
                          >
                            <input
                              type={'radio'}
                              name={'paymentSelect'}
                              value={'paymentButton'}
                              checked={paymentMethodSelect === 'paymentButton'}
                            />
                            <span className='chackBox' />
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              x='0px'
                              y='0px'
                              width={17}
                              height={15}
                              viewBox='0 0 48 48'
                              style={{ fill: '#undefined' }}
                            >
                              <path
                                fill='#FFC107'
                                d='M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z'
                              />
                              <path
                                fill='#FF3D00'
                                d='M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z'
                              />
                              <path
                                fill='#4CAF50'
                                d='M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z'
                              />
                              <path
                                fill='#1976D2'
                                d='M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z'
                              />
                            </svg>
                            Pay
                            <span style={{ margin: '12px' }}>OR</span>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              x='0px'
                              y='0px'
                              width={16}
                              height={15}
                              viewBox='0 0 172 172'
                              style={{ fill: '#undefined' }}
                            >
                              <g
                                fill='none'
                                fillRule='nonzero'
                                stroke='none'
                                strokeWidth={1}
                                strokeLinecap='butt'
                                strokeLinejoin='miter'
                                strokeMiterlimit={10}
                                strokeDasharray=''
                                strokeDashoffset={0}
                                fontFamily='none'
                                fontWeight='none'
                                fontSize='none'
                                textAnchor='none'
                                style={{ mixBlendMode: 'normal' }}
                              >
                                <path d='M0,172v-172h172v172z' fill='none' />
                                <g fill='#ecf0f1'>
                                  <path d='M146.57267,56.10067c-0.7052,0.44147 -17.4924,9.75813 -17.4924,30.41533c0.7912,23.55827 21.18467,31.82 21.5344,31.82c-0.34973,0.44147 -3.0788,11.25453 -11.1628,22.58933c-6.4156,9.76387 -13.5364,19.608 -24.34947,19.608c-10.2856,0 -13.97787,-6.50733 -25.84587,-6.50733c-12.7452,0 -16.35147,6.50733 -26.1096,6.50733c-10.81307,0 -18.46133,-10.3716 -25.22667,-20.04373c-8.7892,-12.6592 -16.25973,-32.5252 -16.52347,-51.6c-0.17773,-10.10787 1.76013,-20.04373 6.67933,-28.4832c6.94307,-11.782 19.33853,-19.78 32.87493,-20.04373c10.3716,-0.34973 19.60227,7.1208 25.93187,7.1208c6.06587,0 17.4064,-7.1208 30.2376,-7.1208c5.5384,0.00573 20.30747,1.67413 29.45213,15.738zM86.00573,38.34453c-1.84613,-9.23067 3.2508,-18.46133 7.998,-24.34947c6.06587,-7.1208 15.64627,-11.954 23.908,-11.954c0.52747,9.23067 -2.81507,18.2836 -8.7892,24.87693c-5.36067,7.1208 -14.59133,12.48147 -23.1168,11.42653z' />
                                </g>
                              </g>
                            </svg>
                            Pay
                          </label>
                        )}
                      </div>
                      <div className='tabFootNav'>
                        <div className='next-previous-btn'>
                          <button
                            className='btn btn-primary btn-sm'
                            id='paybutton'
                            onClick={() => {
                              previous('2');
                            }}
                            type='button'
                          >
                            {t('PREVIOUS')}
                          </button>
                          <button
                            className='btn btn-primary btn-sm'
                            id='paybutton'
                            disabled={
                              (licenceKey() &&
                              isCouponApplied() &&
                              !billingAddress
                                ? true
                                : !formik.values.cardnumber) &&
                              (!(
                                formik.values.address1 !== '' &&
                                regex2.test(formik.values.address1)
                              ) ||
                              !regex2.test(formik.values.address2) ||
                              !(
                                formik.values.city !== '' &&
                                regex2.test(formik.values.city)
                              ) ||
                              !formik.values.country.value ||
                              !(formik.values.country.value === 'US' ||
                              formik.values.country.value === 'CA'
                                ? formik.values.zipcode !== '' && isZipValid
                                : true) ||
                              !(formik.values.country.value === 'CA'
                                ? formik.values.canadastate.value
                                : true) ||
                              !(formik.values.country.value === 'US'
                                ? formik.values.usstate.value
                                : true) ||
                              (userFormData && userFormData.isdirty)
                                ? !userFormData.isdirty
                                : !formik.dirty || !formik.isValid) &&
                              paymentMethodSelect !== 'paymentButton' &&
                              !isIndianCustomer
                            }
                            onClick={() => {
                              next('2');
                            }}
                            type='button'
                          >
                            {t('NEXT')}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tabCont ${
                        hide3 ? 'multipartform-3-hide' : 'multipartform-3-show'
                      }`}
                    >
                      <div className='tabDetails d-flex flex-column'>
                        <div className='text-center h5 font-weight-bold pb-2 border-bottom'>
                          {t('SUBSCRIPTIONSTEP3')}
                          {handlePlanHeading()}
                        </div>
                        {state && state.region === 'ROW' && (
                          <>
                            {match.url.includes('soloconnect') && (
                              <div className='row'>
                                <div className='col-lg-12 col-sm-12'>
                                  <span>{t('LRTCONNECTMODERM')}</span>
                                </div>
                                <div className='col-lg-12 col-sm-12 pt-3'>
                                  <ul className='list-inline d-flex align-items-center flex-wrap mb-0'>
                                    <li className='regionCountry w-100 mb-2 pt-2'>
                                      <span>{t('WHIREGISOLOCONNECT')}</span>
                                    </li>
                                    <li className='regionCountrySelect'>
                                      {/* <div className="lbltxt">{state && state.region}</div> */}
                                      <Select
                                        name='regionDropDown'
                                        value={regionList.find(
                                          (item) =>
                                            item.value ===
                                            formik.values.regionDropDown,
                                        )}
                                        // value={
                                        //   { label:formik.values.regionDropDown.value||formik.values.regionDropDown ,
                                        //     value :formik.values.regionDropDown.value ||formik.values.regionDropDown
                                        //   }}
                                        menuPlacement='top'
                                        onChange={(value) => {
                                          formik.setFieldValue(
                                            'regionDropDown',
                                            value.value,
                                          );
                                          formik.values.regionDropDown =
                                            value.value;
                                          regionChange(value);
                                          setUserFormData({
                                            ...formik.values,
                                            ...{ isdirty: true },
                                          });
                                        }}
                                        options={regionList}
                                        // defaultValue={{
                                        //   value: 'Europe',
                                        //   label: 'Europe',
                                        // }}
                                        onBlur={() => {
                                          formik.setFieldTouched(
                                            'regionDropDown',
                                            true,
                                          );
                                        }}
                                      />
                                    </li>
                                    <li className='infoIcon pl-2'>
                                      <Link
                                        to='#'
                                        onClick={() => retriveModalDetails()}
                                        className='fa fa-question-circle fa-lg helpLink'
                                        aria-hidden='true'
                                      />
                                    </li>
                                    {/* <li className='text-center pl-2'>
                                      {t('CHOOSEREGION')}
                                    </li> */}
                                  </ul>
                                </div>
                              </div>
                            )}
                            <div className='row'>
                              <Divider />
                            </div>
                          </>
                        )}

                        {match.url.includes('soloconnect') && (
                          <div className='lrtplan'>
                            <div className='my-3'>
                              <div className='row cost_blk'>
                                <div className='col-sm-5 col-5'>
                                  {getPlanButton()}
                                </div>
                                <div className='col-sm-5 col-4 cost'>
                                  {t('COST')} :
                                </div>
                                <div className='col-sm-2 col-3'>
                                  <b>
                                    {cookies.get('currency_symbol')}
                                    {state &&
                                    state.region === 'ROW' &&
                                    formik.values.regionDropDown === ''
                                      ? 0
                                      : (amount / 100) * currency.amount}
                                  </b>
                                </div>
                              </div>
                              {
                                <div className='row discount_blk'>
                                  <div className='col-sm-6 col-5'></div>
                                  <div className='col-sm-4 col-4 cost'>
                                    {t('DISCOUNT')} :
                                  </div>
                                  <div className='col-sm-2 col-3'>
                                    <b className='ml-n2'>
                                      - {cookies.get('currency_symbol')}
                                      {state &&
                                      state.region === 'ROW' &&
                                      formik.values.regionDropDown === ''
                                        ? 0
                                        : percent_off
                                        ? `${
                                            (amount * percent_off) / 100 / 100
                                          }`
                                        : `${amount_off / 100}`}
                                    </b>
                                  </div>
                                </div>
                              }
                              {Number(tax) !== 0 && (
                                <React.Fragment>
                                  <div className='row tax_blk'>
                                    <div className='col-sm-10 col-lg-10 col-9 cost d-flex justify-content-end'>
                                      {' '}
                                      {t('TAX')}
                                      <div className='taxtip ml-2'>
                                        ({t('TAXINFO')})
                                      </div>{' '}
                                      :
                                    </div>
                                    <div className='col-sm-2 col-lg-2 col-3'>
                                      <b>
                                        {cookies.get('currency_symbol')}
                                        {parseFloat(getTax()) < 0
                                          ? 0
                                          : parseFloat(getTax())}
                                      </b>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                              {taxLoading && (
                                <div className='row'>
                                  <div className='col-sm-10 col-lg-10 col-9 cost'>
                                    {t('TAX')} :
                                  </div>
                                  <div className='col-sm-2 col-lg-2 col-3 taxloading'>
                                    <ClipLoader
                                      size={15}
                                      title='loading'
                                      color={'#000'}
                                      loading={true}
                                    />
                                  </div>
                                </div>
                              )}
                              {
                                <div className='row  mt-2 pt-2 border-top'>
                                  <div className='col-sm-4 col-5'></div>
                                  {Number(tax) !== 0 ? (
                                    <React.Fragment>
                                      <div className='col-sm-6 col-4 cost'>
                                        {t('PAYABLEAMOUNT')} :
                                      </div>
                                      <div className='col-sm-2 col-3'>
                                        <b>
                                          {cookies.get('currency_symbol')}
                                          {state &&
                                          state.region === 'ROW' &&
                                          formik.values.regionDropDown === ''
                                            ? 0
                                            : parseFloat(getPayableAmount()) +
                                                parseFloat(getTax()) <
                                              0
                                            ? 0
                                            : (
                                                parseFloat(getPayableAmount()) +
                                                parseFloat(getTax())
                                              ).toFixed(2)}
                                        </b>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <div className='col-sm-6 col-4 cost'>
                                        {t('PAYABLEAMOUNT')} :
                                      </div>
                                      <div className='col-sm-2 col-3'>
                                        <b>
                                          {cookies.get('currency_symbol')}
                                          {state &&
                                          state.region === 'ROW' &&
                                          formik.values.regionDropDown === ''
                                            ? 0
                                            : parseFloat(getPayableAmount()) < 0
                                            ? 0
                                            : parseFloat(getPayableAmount())}
                                        </b>
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              }
                              <div className='row'>
                                <div className='col-sm-12 trygraphics'>
                                  {t('YOUR')} {kitConfig}{' '}
                                  {t('TRYOUTSOLOCONNECT')}
                                </div>
                                {isCheckModemRegion() && (
                                  <div className='col-md-12 trygraphics mt-3 notesTxt'>
                                    <i
                                      className='fa fa-info-circle mr-1'
                                      aria-hidden='true'
                                    ></i>
                                    {t('SOLOPROCONNECTUSAONLY')}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {match.url.includes('lrt') && (
                          <div className='lrtplan'>
                            <div className='mb-3'>
                              <div className='row cost_blk'>
                                <div className='col-sm-5 col-5'>
                                  {getPlanButton()}
                                </div>
                                <div className='col-sm-5 col-4 cost'>
                                  {t('COST')} :
                                </div>
                                <div className='col-sm-2 col-3'>
                                  <b>
                                    {cookies.get('currency_symbol')}
                                    {(amount / 100) * currency.amount}
                                  </b>
                                </div>
                              </div>
                              {
                                <div className='row discount_blk'>
                                  <div className='col-sm-6 col-5'></div>
                                  <div className='col-sm-4 col-4 cost'>
                                    {t('DISCOUNT')} :
                                  </div>
                                  <div className='col-sm-2 col-3'>
                                    <b className='ml-n2'>
                                      - {cookies.get('currency_symbol')}
                                      {percent_off
                                        ? `${
                                            (amount * percent_off) / 100 / 100
                                          }`
                                        : `${amount_off / 100}`}
                                    </b>
                                  </div>
                                </div>
                              }
                              {Number(tax) !== 0 && (
                                <React.Fragment>
                                  <div className='row tax_blk'>
                                    <div className='col-sm-10 col-lg-10 col-9 cost d-flex justify-content-end'>
                                      {' '}
                                      {t('TAX')}
                                      <div className='taxtip ml-2'>
                                        ({t('TAXINFO')})
                                      </div>{' '}
                                      :
                                    </div>
                                    <div className='col-sm-2 col-lg-2 col-3'>
                                      <b>
                                        {cookies.get('currency_symbol')}
                                        {parseFloat(getTax()) < 0
                                          ? 0
                                          : parseFloat(getTax())}
                                      </b>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                              {taxLoading && (
                                <div className='row'>
                                  <div className='col-sm-10 col-lg-10 col-9 cost'>
                                    {t('TAX')} :
                                  </div>
                                  <div className='col-sm-2 col-lg-2 col-3 taxloading'>
                                    <ClipLoader
                                      size={15}
                                      title='loading'
                                      color={'#000'}
                                      loading={true}
                                    />
                                  </div>
                                </div>
                              )}
                              {
                                <div className='row mt-2 pt-2 border-top'>
                                  <div className='col-sm-4 col-5'></div>
                                  {Number(tax) !== 0 ? (
                                    <React.Fragment>
                                      <div className='col-sm-6 col-4 cost'>
                                        {t('PAYABLEAMOUNT')} :
                                      </div>
                                      <div className='col-sm-2 col-3'>
                                        <b>
                                          {cookies.get('currency_symbol')}
                                          {parseFloat(getPayableAmount()) +
                                            parseFloat(getTax()) <
                                          0
                                            ? 0
                                            : (
                                                parseFloat(getPayableAmount()) +
                                                parseFloat(getTax())
                                              ).toFixed(2)}
                                        </b>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <div className='col-sm-6 col-4 cost'>
                                        {t('PAYABLEAMOUNT')} :
                                      </div>
                                      <div className='col-sm-2 col-3'>
                                        <b>
                                          {cookies.get('currency_symbol')}
                                          {parseFloat(getPayableAmount()) < 0
                                            ? 0
                                            : parseFloat(getPayableAmount())}
                                        </b>
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              }
                            </div>
                            {licenceKey() && isCouponApplied() ? (
                              <div className='row'>
                                <div className='col-12 trygraphics py-2'>
                                  {t('TRYOUTYEARLY')}
                                </div>
                              </div>
                            ) : (
                              <div className='row'>
                                <div className='col-12 trygraphics py-2'>
                                  {t('TRYOUTLRT')}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {match.url.includes('streamtool') && (
                          <div className='lrtplan'>
                            <div className='mb-3'>
                              <div className='row cost_blk'>
                                <div className='col-sm-5 col-5'>
                                  {getPlanButton()}
                                </div>
                                <div className='col-sm-5 col-4 cost'>
                                  {t('COST')} :
                                </div>
                                <div className='col-sm-2 col-3'>
                                  <b>
                                    {cookies.get('currency_symbol')}
                                    {(amount / 100) * currency.amount}
                                  </b>
                                </div>
                              </div>
                              {
                                <div className='row discount_blk'>
                                  <div className='col-sm-6 col-5'></div>
                                  <div className='col-sm-4 col-4 cost'>
                                    {t('DISCOUNT')} :
                                  </div>
                                  <div className='col-sm-2 col-3'>
                                    <b className='ml-n2'>
                                      - {cookies.get('currency_symbol')}
                                      {percent_off
                                        ? `${
                                            (amount * percent_off) / 100 / 100
                                          }`
                                        : `${amount_off / 100}`}
                                    </b>
                                  </div>
                                </div>
                              }
                              {Number(tax) !== 0 && (
                                <React.Fragment>
                                  <div className='row tax_blk'>
                                    <div className='col-sm-10 col-lg-10 col-9 cost d-flex justify-content-end'>
                                      {' '}
                                      {t('TAX')}
                                      <div className='taxtip ml-2'>
                                        ({t('TAXINFO')})
                                      </div>{' '}
                                      :
                                    </div>
                                    <div className='col-sm-2 col-lg-2 col-3'>
                                      <b>
                                        {cookies.get('currency_symbol')}
                                        {parseFloat(getTax()) < 0
                                          ? 0
                                          : parseFloat(getTax())}
                                      </b>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                              {taxLoading && (
                                <div className='row'>
                                  <div className='col-sm-10 col-lg-10 col-9 cost'>
                                    {t('TAX')} :
                                  </div>
                                  <div className='col-sm-2 col-lg-2 col-3 taxloading'>
                                    <ClipLoader
                                      size={15}
                                      title='loading'
                                      color={'#000'}
                                      loading={true}
                                    />
                                  </div>
                                </div>
                              )}
                              {
                                <div className='row mt-2 pt-2 border-top'>
                                  <div className='col-sm-4 col-5'></div>
                                  {Number(tax) !== 0 ? (
                                    <React.Fragment>
                                      <div className='col-sm-6 col-4 cost'>
                                        {t('PAYABLEAMOUNT')} :
                                      </div>
                                      <div className='col-sm-2 col-3'>
                                        <b>
                                          {cookies.get('currency_symbol')}
                                          {parseFloat(getPayableAmount()) +
                                            parseFloat(getTax()) <
                                          0
                                            ? 0
                                            : (
                                                parseFloat(getPayableAmount()) +
                                                parseFloat(getTax())
                                              ).toFixed(2)}
                                        </b>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <div className='col-sm-6 col-4 cost'>
                                        {t('PAYABLEAMOUNT')} :
                                      </div>
                                      <div className='col-sm-2 col-3'>
                                        <b>
                                          {cookies.get('currency_symbol')}
                                          {parseFloat(getPayableAmount()) < 0
                                            ? 0
                                            : parseFloat(getPayableAmount())}
                                        </b>
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              }
                            </div>

                            <div className='row'>
                              <div className='col-12 trygraphics py-2'>
                                {t('TRYOUTSRT')}
                              </div>
                            </div>
                          </div>
                        )}

                        <div className='btm-row'>
                          <p>
                            {match.url.includes('lrt') && (
                              <a
                                rel='noopener noreferrer'
                                target='_blank'
                                href='https://get.gosolo.tv/termsandconditions'
                              >
                                {t('READTERMSANDCONDITION')}
                              </a>
                            )}
                            {match.url.includes('soloconnect') && (
                              <a
                                rel='noopener noreferrer'
                                target='_blank'
                                href='https://get.gosolo.tv/termsandconditions-soloconnect'
                              >
                                {t('READTERMSANDCONDITION')}
                              </a>
                            )}
                            {match.url.includes('streamtool') && (
                              <a
                                rel='noopener noreferrer'
                                target='_blank'
                                href='https://get.gosolo.tv/termsandconditions'
                              >
                                {t('READTERMSANDCONDITION')}
                              </a>
                            )}
                          </p>
                          <p className='customCheckBox'>
                            <input
                              className='checkbox-row'
                              type='checkbox'
                              checked={formik.values.tnc_checked}
                              onClick={() => {
                                formik.setFieldValue(
                                  'tnc_checked',
                                  !formik.values.tnc_checked,
                                );
                              }}
                            />
                            <span>{t('AGREETERMSANDCONDITION')}</span>
                          </p>
                          {/* <p>{t('PRESSSUBSCRIBE')}</p> */}
                        </div>
                        {isIndianCustomer && (
                          <p className='border f-size-12 mt-3 p-2 rounded text-center msg-box'>
                            {t('RBIGUIDELINE')}
                          </p>
                        )}
                        <p className='mt-auto text-center text-muted font-size pt-3'>
                          {t('PRESSSUBSCRIBE')}
                        </p>
                      </div>

                      <div className='tabFootNav'>
                        <div className='next-previous-btn'>
                          <button
                            className='btn btn-primary btn-sm'
                            id='paybutton'
                            onClick={() => {
                              previous('3');
                              formik.setFieldValue('tnc_checked', false);
                            }}
                            type='button'
                          >
                            {t('PREVIOUS')}
                          </button>
                          {(paymentMethodSelect === 'card' ||
                            parseFloat(getPayableAmount()) === 0) && (
                            <button
                              className='btn btn-primary btn-sm'
                              id='paybutton'
                              disabled={
                                (state &&
                                  state.region === 'ROW' &&
                                  formik.values.regionDropDown === '') ||
                                !formik.values.tnc_checked ||
                                !isEmpty(formik.errors) ||
                                submitDisabled ||
                                (!formik.values.cardnumber &&
                                  formik.values.country.value === 'US' &&
                                  (!isZipValid || taxLoading)) ||
                                isCheckModemRegion()
                              }
                              type='submit'
                            >
                              {t('SUBSCRIBE')}
                            </button>
                          )}
                          {paymentRequest &&
                            paymentMethodSelect === 'paymentButton' &&
                            parseFloat(getPayableAmount()) !== 0 && (
                              <div
                                className={
                                  !formik.values.tnc_checked
                                    ? 'disabledPaymentButton'
                                    : ''
                                }
                              >
                                <PaymentRequestButtonElement
                                  options={{ paymentRequest }}
                                  onClick={setPaymentMethod}
                                  te
                                />
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
